<template lang="pug">
  div.d-flex.flex-column.wrap-module-select-plan
    div.container-select-plan.py16
      div(v-for="plan in newPlans"
        :key="plan.id"
        :class="{'selected': selectedPlanId == plan.id, 'disabled': currentPlanId && currentPlanId === plan.id}"
        @click="!(currentPlanId && currentPlanId === plan.id) ? selectedPlanId = plan.id : null"
        ).card-plan.px12.py14.mb12
        div.f.fm.flex-between.mb12
          div.f.fm
            span.fz16.mr4 {{plan.name}}
            span(v-if="plan.pricePerUserPerMonth > 0").fz12 （{{plan.pricePerUserPerMonthStr}}円/人/月）
            span(v-else).fz12 （無料）
          v-icon(:color="selectedPlanId == plan.id ? '#4285f4' : '#999'") mdi-checkbox-marked-circle
        div.wrap-info.f.flex-between
          ul
            li(v-for="feature in plan.features"
              :key="feature").fz12.mb2 {{feature}}
          div.f.fb.link-details
            a(href="https://www.meetawesome.jp/plans" target="brank").fz12 詳細
    ItemButtomBtn(label="次へ"
      :active="selectedPlanId ? true : false"
      @onBtn="next")

</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-select-plan {
  .container-select-plan {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
    .card-plan {
      background: $dark_primary_text_color;
      border: $basic_border;
      border-radius: 9px;
      filter: $drop_shadow;
      &.selected {
        border-width: 2px;
        border-color: $active_color;
      }
    }
    .disabled {
      color: gray;
    }
    .link-details {
      min-width: 26px;
    }
  }
}
</style>

<script>
import format from 'date-fns/format'
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const teamStore = createNamespacedHelpers('team')
const planSettingsStore = createNamespacedHelpers('planSettings')

import { PLANS } from '@/components/utils/plans'

import ItemButtomBtn from '@/components/item/ItemButtomBtn'
import { sendTrackingEvent } from '../utils/eventTracker'

export default {
  components: {
    ItemButtomBtn
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...teamStore.mapState(['team']),
    ...planSettingsStore.mapState(['planSettings']),
    newPlans () {
      if (this.team) {
        return this.plans.filter(p => p.id !== 'TEAM')
      } else {
        return this.plans.filter(p => p.id !== 'BASIC')
      }
    }
  },
  data () {
    return {
      selectedPlanId: null,
      currentPlanId: null,
      plans: PLANS,
    }
  },
  async created () {
    this.selectedPlanId = this.planSettings.planId
    this.currentPlanId = (this.team) ? 'TEAM' : null
  },
  watch: {
    team() {
      this.currentPlanId = (this.team) ? 'TEAM' : null
    }
  },
  methods: {
    ...planSettingsStore.mapMutations(['updatePlanSettings']),
    async next() {
      this.updatePlanSettings({
        planId: this.selectedPlanId,
      })
      const plan = PLANS.find((v) => v.id === this.selectedPlanId)
      if (plan && plan.pricePerUserPerMonth === 0) {
        const user = await database.userCollection().findById(this.uid)
        if (user.stripeCustomerId) {
          this.$router.push('/downgrade-plan')
        } else {
          this.$router.push('/home')
        }
      } else {
        this.$router.push('/register-credit-card')
      }
      sendTrackingEvent(`${this.$route.path}:next`, {
        planId: this.selectedPlanId
      }, this.uid)
    }
  }
}
</script>

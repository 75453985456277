export const PLANS = [
  {
    id: 'TEAM',
    name: 'チーム',
    features: [
      'チームメンバーの管理',
      'Zoomアカウントの連携（近日公開予定）',
      'Microsoft Teamsアカウントの連携'
    ],
    pricePerUserPerMonth: 2000,
    pricePerUserPerMonthStr: '2,000'
  },
  {
    id: 'BASIC',
    name: 'ベーシック',
    features: [
      '自動ミーティング調整の基本機能',
      'Google Calendar, Google Meetの連携',
      '調整済みミーティングの管理機能'
    ],
    pricePerUserPerMonth: 0,
    pricePerUserPerMonthStr: '0'
  }
]
